import React, { useState, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

const pages = ["Home", "About", "Bulk Inquiry", "Products", "Contact"];

function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [anchorElResponsiveProducts, setAnchorElResponsiveProducts] =
    useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenProductsMenu = (event) => {
    clearTimeout(timeoutRef.current);
    setAnchorElProducts(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseProductsMenu = () => {
    timeoutRef.current = setTimeout(() => {
      setMenuOpen(false);
      setAnchorElProducts(null);
    }, 200); // Delay closing to handle hover transition
  };

  const handleOpenResponsiveProductsMenu = (event) => {
    setAnchorElResponsiveProducts(event.currentTarget);
  };

  const handleCloseResponsiveProductsMenu = () => {
    setAnchorElResponsiveProducts(null);
  };

  return (
    <AppBar position="static" className="nac">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box
            component="a"
            href=""
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              mr: 1,
            }}
          >
            <img
              src="logo.png"
              alt="Fashage Logo"
              style={{
                height: "60px",
                marginRight: "10px",
                marginTop: "0.5rem",
                borderWidth: "5rem",
              }}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              gap: 2,
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  navigate(
                    page === "Home"
                      ? "/"
                      : `/${page.toLowerCase().replace(/ /g, "-")}`
                  );
                }}
                sx={{
                  my: 0,
                  color: "black",
                  display: "block",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  textTransform: "uppercase",
                  position: "relative",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "black",
                  },
                  "&::after": {
                    content: '""',
                    display: "block",
                    width: 0,
                    height: "2px",
                    background: "black",
                    transition: "width 0.3s",
                    position: "absolute",
                    bottom: "-4px",
                    left: "0",
                  },
                  "&:hover::after": {
                    width: "100%",
                  },
                }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {/* Responsive Menu Icon and Menu */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  backgroundColor: "#d8e6ed", // Set background color
                },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    navigate(
                      page === "Home"
                        ? "/"
                        : `/${page.toLowerCase().replace(/ /g, "-")}`
                    );
                    handleCloseNavMenu();
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      textTransform: "none !important",
                    }}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
