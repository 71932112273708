import React from "react";
import Slider from "react-slick";
import "./car.css";
const Product = ({ category, images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(4, images.length), // Number of images to show in a single slide
    slidesToScroll: 1,
  
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(3, images.length),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, images.length),
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: Math.min(1, images.length),
        },
      },
    ],
  };

  return (
    <div className="product-carousel">
      <div className="products">{category}</div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`${category} - ${index + 1}`}
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                userSelect: "none",
                WebkitUserDrag: "none",
                pointerEvents: "none",
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Product;
