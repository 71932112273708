import React from "react";
import { Typography, Box, Grid } from "@mui/material";

const Customization = () => {
  const points1 = [
    "Brightens up the office neutrals.",
    "Hides ketchup stains.",
    "Warms u on a chilly evening or harsh thanda thanda AC.",
    "Prevents your hair tangling up on a long ride.",
    "Gives u a bit of style.",
    "And could be fun in the bedroom as well.",
    "Also to Cover the neckline when u want it to be covered",
  ];

  const points2 = [
    "Lightweight and Breathable for summer comfort.",
    "Insulating materials for winter coziness.",
    "Neutral Moisture-Wicking Fabrics for active environments.",
    "Warm and Insulating materials for winter coziness.",
    "Neutral Colors and Classic Patterns for versatility.",
    "All-Weather Silk for year-round elegance.",
    "Easy to Style with versatile lengths and shapes.",
    "Durability and Low Maintenance for long-lasting wear.",
    "Wrinkle-Resistant for a fresh look on busy days.",
  ];

  const renderPoints = (points) =>
    points.map((point, index) => (
      <li
        key={index}
      
        className="pts"
      >
      
        {point}
      </li>
    ));

  return (
    <>
      <Box sx={{ padding: "2rem", backgroundColor: "white" }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            color: "#936b08",
            marginBottom: "1rem",
            textAlign: "center",
            fontWeight: "900",
          }}
        >
          Why Scarf
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src="Designer11.png"
              alt="Customization"
              sx={{
                width: "100%",
                borderRadius: "8px",
              }}
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                userSelect: "none",
                WebkitUserDrag: "none",
                pointerEvents: "none",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <ul  >
              {renderPoints(points1)}
            </ul>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: "2rem", backgroundColor: "white" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src="Designer12.png"
              alt="Customization"
              sx={{
                width: "100%",
                borderRadius: "8px",
              }}
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                userSelect: "none",
                WebkitUserDrag: "none",
                pointerEvents: "none",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <ul  >
              {renderPoints(points2)}
            </ul>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Customization;
