import React from 'react'
import Nav from './Nav'
import Carousal from "./Crousal";
import Trending from './Trending';
import Customization from './Custom1'; 
function HomePage() {
  return (
    <>
      <Carousal></Carousal>
      <Trending></Trending>
      <Customization></Customization>
      <div className="video-par">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/LLKHPtJGo8c"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="video destination "
        ></iframe>
      </div>
    </>
  );
}

export default HomePage