import React from "react";
import { Typography, Box } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function ContactForm() {
  return (
    <>
      <div className="iframeparent">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.858514007902!2d73.0171890804257!3d19.24499703979808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bdd695ed32dd%3A0x6ef265c70efadeb5!2sDipisha%20Fashline%20Enterprises%20LLP!5e0!3m2!1sen!2sin!4v1691221252546!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="iframe hero-mid"
        ></iframe>
      </div>
      <Box
        sx={{ padding: 3, maxWidth: 800, margin: "0 auto", textAlign: "left" }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center" }}
          className="cnt1"
        >
          Connect With Us
        </Typography>

        <Typography variant="h5" gutterBottom className="cnt">
          Our Address
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ textAlign: "left" }}
          className="abtp"
        >
          Dipisha Fashline Enterprises LLP <br />
          Bldg I, Gala no.104, Shree Rajlaxmi Complex, Kalher, <br />
          Bhiwandi 421302, Maharashtra, India
        </Typography>

        <Typography variant="h5" gutterBottom className="cnt">
          Tel:
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ textAlign: "left" }}
          className="abtp"
        >
          <PhoneIcon /> 702 145 3815
        </Typography>

        <Typography variant="h5" gutterBottom className="cnt">
          Mob:
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ textAlign: "left" }}
          className="abtp"
        >
          <PhoneIcon /> 981 949 0635
        </Typography>

        <Typography variant="h5" gutterBottom className="cnt">
          Email:
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ textAlign: "left" }}
          className="abtp"
        >
          <EmailIcon></EmailIcon> 
          <a href="mailto:dipishafashline@gmail.com">
               {" "} dipishafashline@gmail.com
          </a>
        </Typography>
      </Box>
    </>
  );
}

export default ContactForm;
