import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import HomePage from "./Components/HomePage";
import Feed from "./Components/Feed";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Footer from "./Components/Footer";
import Nav from "./Components/Nav";
import Contact from "./Components/Contact";
import Bulk from './Components/Bulk'
import ContactForm from "./Components/Contact";
import ProductJ from "./Components/ProductJ";
function App() {
  return (
    <BrowserRouter>
      <Nav />

      <Routes>
        <Route path="/about" element={<Feed />} />

        <Route path="/" element={<HomePage />} />

        <Route path="/contact-us" element={<Contact></Contact>} />

        <Route path="/bulk-inquiry" element={<Bulk></Bulk>} />
        <Route path="/contact" element={<ContactForm></ContactForm>} />
        <Route path="/products" element={<ProductJ></ProductJ>} />
      </Routes>

      <Footer></Footer>
    </BrowserRouter>
  ); 
}

export default App;
