import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 // Import your custom CSS file here

function Carousal() {
  const settings = {
    infinite: true,
    speed: 500,
    fade: true,
    arrows: false,
    pauseOnHover: false,
    autoplay: true,
  };
  return (
    <Slider {...settings} className="slider">
      <div className="slider-item">
        <img
          alt="Hero"
          src={
            "https://res.cloudinary.com/dakgnkwwc/image/upload/v1694354198/s5_lf3wrp.jpg"
          }
          className="heroimg"
          draggable="false"
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div className="slider-item">
        <img
          alt="Hero"
          src={
            "https://res.cloudinary.com/dakgnkwwc/image/upload/v1694354184/s7_te23qc.jpg"
          }
          className="heroimg"
          draggable="false"
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div className="slider-item">
        <img
          alt="Hero"
          src={
            "https://res.cloudinary.com/dakgnkwwc/image/upload/v1694354212/s3_ktxjrm.jpg"
          }
          className="heroimg"
          draggable="false"
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    </Slider>
  );
}

export default Carousal;
