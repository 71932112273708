import React from 'react'
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import emb1 from "../assets/emb1.png";
import emb2 from "../assets/emb2.png";
import emb3 from "../assets/emb3.png";
import bg1 from "../assets/bg1.png";
import bg2 from "../assets/bg2.png";
import bg3 from "../assets/bg3.png";
import td1 from "../assets/td1.png";
import td2 from "../assets/td2.png";
import td3 from "../assets/td3.png";
function Trending() {
  return (
    <div>
      <div className=" text-center  oc trending">Trending Now</div>
      <div className="card1">
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={emb1}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={emb2}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={emb3}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={bg1}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={bg2}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={bg3}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={td1}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={td2}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
        <Card className="c1" sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            height="500"
            image={td3}
            alt="green iguana"
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
            style={{
              userSelect: "none",
              WebkitUserDrag: "none",
              pointerEvents: "none",
            }}
          />
        </Card>
      </div>
      <div className="parentmore">
        <a href="/Products" className="view-par">
          <button className="view">View More</button>
        </a>
      </div>
    </div>
  );
}

export default Trending