import React from "react";
import { Typography, Box,Grid } from "@mui/material";

function Feed() {
  return (
    <Box className="about-box">
      <Box sx={{ padding: "2rem", backgroundColor: "white" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src="Designer9.png"
              alt="About"
              sx={{
                width: "100%",
                borderRadius: "8px",
              }}
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                userSelect: "none",
                WebkitUserDrag: "none",
                pointerEvents: "none",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" sx={{ textAlign: "left" }} className="abt">
              FASHION, FASHION AND MORE FASHION!
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "left" }}
              className="abtp"
            >
              A specialized brand from the haute design studios of Dipisha
              Fashline Enterprises LLP founded by Deepak Nathwani that aims to
              revolutionize the way the women of India dress.
            </Typography>

            <Typography variant="h5" sx={{ textAlign: "left" }} className="abt">
              Our Mission
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "left" }}
              className="abtp"
            >
              To become a brand that will revolutionize the way women dress in
              India.
            </Typography>

            <Typography variant="h5" sx={{ textAlign: "left" }} className="abt">
              Our Vision
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "left" }}
              className="abtp"
            >
              To design top-quality scarves, stoles, and wraps that go with
              every kind of apparel for every occasion and define the style
              statement of women of every age group.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Feed;
