import React from "react";
import Product from "./Product.js";
import "../App.css";
///////////////////////////////// Lace
import lace1 from "../assets/lace (1).png";
import lace2 from "../assets/lace (2).png";
import lace3 from "../assets/lace (3).png";
import lace4 from "../assets/lace (4).png";
import lace5 from "../assets/lace (5).png";
import lace6 from "../assets/lace (6).png";
import lace7 from "../assets/lace (7).png";
import lace8 from "../assets/lace (8).png";
import lace9 from "../assets/lace (9).png";
import lace10 from "../assets/lace (10).png";
import lace11 from "../assets/lace (11).png";
import lace12 from "../assets/lace (12).png";

///////////////////////////////////  Printed
import print1 from "../assets/print (1).jpeg";
import print2 from "../assets/print (2).jpeg";
import print3 from "../assets/print (3).jpeg";
import print4 from "../assets/print (4).jpeg";
import print5 from "../assets/print (5).jpeg";
import print6 from "../assets/print (6).jpeg";
import print7 from "../assets/print (7).jpeg";
import print8 from "../assets/print (8).jpeg";
import print9 from "../assets/print (9).jpeg";
import print10 from "../assets/print (10).jpeg";

////////////////////////////////////////// Shaded
import td1 from "../assets/td1.png";
import td2 from "../assets/td2.png";
import td3 from "../assets/td3.png";
import td4 from "../assets/td4.png";
import td5 from "../assets/td5.png";
import td6 from "../assets/td6.png";
import td7 from "../assets/td7.png";

//////////////////////////////////////////////Big Size
import bg1 from "../assets/bg1.png";
import bg2 from "../assets/bg2.png";
import bg3 from "../assets/bg3.png";
import bg4 from "../assets/bg4.png";
import bg5 from "../assets/bg5.png";
import bg6 from "../assets/bg6.png";
import bg7 from "../assets/bg7.png";
//////////////////////////////////////////////////////Embroided

import emb1 from "../assets/emb1.png";
import emb2 from "../assets/emb2.png";
import emb3 from "../assets/emb3.png";

const ProductJ = () => {
  const categories = [
    {
      name: "Lace scarves",
      images: [
        lace1,
        lace2,
        lace3,
        lace4,
        lace5,
        lace6,
        lace7,
        lace8,
        lace9,
        lace10,
        lace11,
        lace12,
      ],
    },
    {
      name: "Printed Scarves-50 X 180 cms",
      images: [
        print1,
        print2,
        print3,
        print4,
        print5,
        print6,
        print7,
        print8,
        print9,
        print10,
      ],
    },
    {
      name: "Shaded & Tie- Dye Scarves",
      images: [td1, td2, td3, td4, td5, td6, td7],
    },
    {
      name: "Big size scarves",
      images: [bg1, bg2, bg3, bg4, bg5, bg6, bg7],
    },
    {
      name: "Embroidered Scarves",
      images: [emb1, emb2, emb3],
    },

    // Add more categories as needed
  ];

  return (
    <div className="Products">
      {categories.map((category, index) => (
        <Product
          key={index}
          category={category.name}
          images={category.images}
          className="temp-product"
        />
      ))}
    </div>
  );
};

export default ProductJ;
