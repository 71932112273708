import React from "react";
import {
  Container,
  Typography,
  Box,
  Link as MuiLink,
  Grid,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        py: 2, // Decreased padding-y to 4
        backgroundColor: "#d8e6ed",
        textAlign: "left", // Aligning content to the left
        display: "flex",
        flexDirection: "column",
        minHeight: "4vh", // Ensures the footer extends to the bottom of the viewport
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={4} style={{ flexGrow: 1 }}>
          {/* Left column for navigation links */}
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
              <MuiLink
                component={Link}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </MuiLink>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
              <MuiLink
                component={Link}
                to="/about"
                color="inherit"
                underline="hover"
              >
                About
              </MuiLink>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
              <MuiLink
                component={Link}
                to="/products"
                color="inherit"
                underline="hover"
              >
                Our Products
              </MuiLink>
            </Typography>

            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
              <MuiLink
                component={Link}
                to="/contact"
                color="inherit"
                underline="hover"
              >
                Contact Us
              </MuiLink>
            </Typography>
          </Grid>

          {/* Right column for additional information */}
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
              Contact Us: <PhoneIcon />
              981 949 0635
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
              Email Us: <EmailIcon />{" "}
              <a href="mailto:dipishafashline@gmail.com">
                dipishafashline@gmail.com
              </a>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
              🔒 Privacy Policy
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
              📜 Terms of Service
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
          © {new Date().getFullYear()} Dipisha Fashline Enterprises LLP. All
          rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
