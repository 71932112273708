import emailjs from "emailjs-com";
import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";

function Bulk() {
  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
  emailjs.init(EMAILJS_USER_ID);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    body: "",
    contactNumber: "",
  });

  const [sent, setsent] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const sendEmail = async (data) => {
    setsent("Sending...");
    try {
      const message = data.body + "\n Contact Number : " + data.contactNumber;
      const emailParams = {
        to_name: "Deepak Nathwani",
        from_name: data.name,
        from_email: data.email,
        subject: data.subject,
        message: message,
        customParam1: data.contactNumber,
      };

      await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, emailParams);

      setFormData({
        name: "",
        email: "",
        subject: "",
        body: "",
        contactNumber: "",
      });
      setsent("✔️ Email sent successfully ");
    } catch (error) {
      console.error("Error sending email:", error);
      setsent("Failed Try Again");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(formData);
  };

  return (
    <Box sx={{ padding: "2rem", backgroundColor: "white" }}>
      <Box
        className="form-container"
        sx={{
          padding: "2rem",
          backgroundColor: "white",
          borderRadius: "8px",
          width: { xs: "100%", sm: "80%", md: "50%" },
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
         className="reach"
        >
          Reach Out To Us
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "green",
            marginBottom: "1rem",
          }}
        >
          {sent}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Your Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Your Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Your Contact Number"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
            margin="normal"
            variant="outlined"
            inputProps={{
              pattern: "\\d{10}",
              title: "Please enter a valid 10-digit contact number.",
            }}
          />
          <TextField
            fullWidth
            label="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Message Body"
            name="body"
            value={formData.body}
            onChange={handleChange}
            required
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              marginTop: "1rem",
              padding: "0.75rem",
              borderRadius: "8px",
              backgroundColor: "#1976d2", // Primary button color
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#115293", // Darker shade on hover
              },
            }}
          >
            Send Email
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default Bulk;
